import { ExclamationCircleOutlined } from '@ant-design/icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faChevronLeft, faEye, faFileDownload, faPlus, faSave, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from '@reach/router'
import { Button, Col, Form, Input, Modal, notification, Row, Select, Spin, Table } from 'antd'
import { navigate } from 'gatsby'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { isManager } from '../../../../api/auth'
import client from '../../../../api/client'
import { CreatePackage } from '../../../../components/admin'
import { SHIPMENT_TYPES, SHIPMENT_TYPES_TOENG } from '../../../../constants/enum'

const subMenus = [
  { name: 'Багцын жагсаалт', dataIndex: 'packages' },
  { name: 'Илгээмжийн жагсаалт', dataIndex: 'orders' },
]

const ShipmentDetails = () => {
  const shipmentId = useParams()?.shipmentId
  const [shipmentDetailsFormRef] = Form.useForm()
  const [createPackageFormRef] = Form.useForm()
  const [addPackageModalVisible, setAddPackageModalVisible] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState('packages')
  const [shipmentStatuses, setShipmentStatuses] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(true)
  const [apiLoading, setApiLoading] = useState(false)
  const [searches, setSearches] = useState({ packageSearch: '', orderSearch: '' })
  const [paginatedRequestDataPackage, setPaginatedRequestDataPackage] = useState({
    search: '',
    pagination: { current: 1, pageSize: 10 },
    sorter: { field: 'totalAmount', order: 'descend' },
  })
  const [paginatedRequestDataOrder, setPaginatedRequestDataOrder] = useState({
    search: '',
    pagination: { current: 1, pageSize: 10 },
    sorter: { field: 'amount', order: 'descend' },
  })
  const [shipmentDetails, setShipmentDetails] = useState({})
  const [packagesInfo, setPackagesInfo] = useState([])
  const [ordersInfo, setOrdersInfo] = useState([])
  const [branches, setBranches] = useState([])

  const [branchId, setBranchId] = useState(0)

  useEffect(() => {
    client.get('/api/lookup/branches').then((res) => {
      if (res.status === 200) {
        setBranches(res.data.map((b) => ({ value: b.id, label: b.name })))
      }
    })
  }, [])

  const fetchShipmentDetails = useCallback(() => {
    client
      .get(`/api/shipments/${shipmentId}`)
      .then(async (res) => {
        setBranchId(res.data?.branchId)
        shipmentDetailsFormRef.setFieldsValue({
          ...res.data,
          shipmentStatus: res.data.shipmentStatusId,
          date: moment(res.data.date).format('MM/DD/YYYY'),
          shipmentType: SHIPMENT_TYPES[res.data.shipmentType],
        })
        setShipmentDetails({
          packages: res.data.packageCount ?? 0,
          orders: res.data.orderCount ?? 0,
          date: res.data.date,
        })
      })
      .catch((err) => {
        console.error(err.response)
        if (err.response.status === 404) navigate('/admin/404')
      })
      .then(() => setLoading(false))
  }, [shipmentId, shipmentDetailsFormRef])

  useEffect(() => {
    const fetchShipmentStatuses = async () => {
      await client
        .get(`/api/lookup/shipmentstatuses`)
        .then((res) => {
          setShipmentStatuses(res.data.map((el) => ({ value: el.id, label: el.name, description: el.description })))
        })
        .catch((err) => console.error({ err }))
    }
    fetchShipmentStatuses()
    fetchShipmentDetails()
  }, [fetchShipmentDetails])

  useEffect(() => {
    if (!addPackageModalVisible) setApiLoading(false)
  }, [addPackageModalVisible])

  const fetchShipmentPackages = useCallback(() => {
    client
      .post(`/api/shipments/${shipmentId}/packages/paginated`, { ...paginatedRequestDataPackage })
      .then(async (res) => {
        setPackagesInfo({ tableData: res.data.data })
      })
      .catch((err) => console.error({ err }))
      .then(() => setTableLoading(false))
  }, [shipmentId, paginatedRequestDataPackage])

  const fetchShipmentOrders = useCallback(() => {
    client
      .post(`/api/shipments/${shipmentId}/orders/paginated`, { ...paginatedRequestDataOrder })
      .then(async (res) => {
        setOrdersInfo({ tableData: res.data.data })
      })
      .catch((err) => console.error({ err }))
      .then(() => setTableLoading(false))
  }, [shipmentId, paginatedRequestDataOrder])

  useEffect(() => {
    if (activeSubMenu === 'packages') {
      fetchShipmentPackages()
    } else if (activeSubMenu === 'orders') {
      fetchShipmentOrders()
    }
  }, [fetchShipmentPackages, fetchShipmentOrders, activeSubMenu])

  const checkHasPendingOrders = async (shipmentType) => {
    const response = await client.post(`/api/admin/orders/paginated`, {
      search: '',
      pagination: { current: 1, pageSize: -1 },
      sorter: { field: 'createdDate', order: 'descend' },
      adminStatus: ['ReadyForSend'],
      shipmentTypes: [SHIPMENT_TYPES_TOENG[shipmentType]],
    })
    if (response.data.data.length > 0) return true
    else return false
  }

  const updateShipmentDetails = async (values) => {
    // On the way status
    if (values.shipmentStatus === 2) {
      const hasPendingOrders = await checkHasPendingOrders(values.shipmentType)
      if (hasPendingOrders) {
        Modal.confirm({
          title: 'Анхааруулга',
          icon: <ExclamationCircleOutlined />,
          content: 'Багцлагдаагүй илгээмжүүд байна. Үргэлжлүүлэх үү?',
          okText: 'Тийм',
          cancelText: 'Үгүй',
          centered: true,
          maskClosable: true,
          onOk() {
            submitUpdatedShipmentDetails(values)
          },
        })
      } else {
        submitUpdatedShipmentDetails(values)
      }
    } else {
      submitUpdatedShipmentDetails(values)
    }
  }

  const submitUpdatedShipmentDetails = (values) => {
    const data = {
      description: values.description,
      shipmentDate: values.date,
      shipmentType: SHIPMENT_TYPES_TOENG[values.shipmentType],
      branchId: values.branchId,
      shipmentStatus: shipmentStatuses.find((x) => x.value === values.shipmentStatus).description,
    }
    client
      .put(`/api/shipments/${shipmentId}`, data)
      .then(async (res) => {
        notification.success({ message: 'Богцын мэдээллийг амжилттай солилоо!' })
        fetchShipmentDetails()
      })
      .catch((err) => console.error({ err }))
  }

  const createPackage = (values) => {
    setApiLoading(true)
    const endpoint = `/api/shipments/${shipmentId}/packages`

    client
      .post(endpoint, { ...values })
      .then(async (res) => {
        notification.success({ message: 'Багцыг амжилттай бүртгэлээ!' })
        fetchShipmentPackages()
        setAddPackageModalVisible(false)
      })
      .catch((err) => {
        console.error({ err })
        setApiLoading(false)
      })
  }

  const removeOrder = (packageId, barcode) => {
    if (packageId && barcode)
      Modal.confirm({
        title: 'Энэ илгээмжийг хасахдаа итгэлтэй байна уу?',
        icon: <ExclamationCircleOutlined />,
        className: 'sb-modal',
        okText: 'Хасах',
        okType: 'danger',
        cancelText: 'Цуцлах',
        centered: true,
        onOk: () => {
          client
            .put(`/api/shipments/packages/${packageId}/addorder/${barcode}`)
            .then(async (res) => {
              fetchShipmentOrders()
              fetchShipmentDetails()
              notification.success({ message: 'Илгээмжийг амжилттай хаслаа!' })
            })
            .catch((err) => console.error({ err }))
        },
      })
  }

  const deletePackage = (packageId) => {
    Modal.confirm({
      title: 'Энэ багцыг хасахдаа итгэлтэй байна уу?',
      icon: <ExclamationCircleOutlined />,
      className: 'sb-modal',
      okText: 'Хасах',
      okType: 'danger',
      cancelText: 'Цуцлах',
      centered: true,
      onOk: () => {
        const endpoint = `/api/shipments/${shipmentId}/packages/${packageId}`

        client
          .delete(endpoint)
          .then(async (res) => {
            notification.success({ message: 'Багцыг амжилттай хаслаа!' })
            fetchShipmentPackages()
          })
          .catch((err) => {
            if (err.response.status === 406)
              notification.error({
                message: 'Багцанд илгээмж байгаа тул хасаж чадсангүй!',
                description: 'Багцыг хоослоод дахин оролдоно уу!',
              })
            else notification.error({ message: err })
            console.error({ err })
          })
      },
    })
  }

  const packagesColumns = [
    { title: 'Нэр', className: 'text-center', dataIndex: 'name' },
    { title: 'Нийт жин', className: 'text-center', dataIndex: 'weight' },
    { title: 'Нийт хайрцагны тоо', className: 'text-center', dataIndex: 'orderCount' },
    { title: 'Нийт төлбөр', className: 'text-center', dataIndex: 'totalAmount' },
    { title: 'АНУ-д', className: 'text-center', dataIndex: 'totalAmountInUSA' },
    { title: 'Монголд', className: 'text-center', dataIndex: 'totalAmountInMGL' },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      render: (record) => (
        <>
          <Button
            className="btn-sb btn-sb-primary me-3"
            style={{ width: 40 }}
            onClick={() => navigate(`/admin/shipments/${shipmentId}/package/${record.id}/${branchId}`)}
          >
            <FontAwesomeIcon icon={faEye} size="sm" />
          </Button>
          {!isManager() && (
            <Button className="btn-sb btn-sb-danger" style={{ width: 40 }} onClick={() => deletePackage(record.id)}>
              <FontAwesomeIcon icon={faTrashAlt} size="sm" />
            </Button>
          )}
        </>
      ),
    },
  ]
  const ordersColumns = isManager()
    ? [
        { title: 'Дугаар', className: 'text-center', dataIndex: 'code' },
        { title: 'Нийт жин', className: 'text-center', dataIndex: 'weight', render: (weight) => `${weight} кг` },
        { title: 'Нийт төлбөр', className: 'text-center', dataIndex: 'amount', render: (amount) => `$${amount}` },
        { title: 'Илгээгч', className: 'text-center', dataIndex: 'sender' },
        { title: 'Хүлээн авагч', className: 'text-center', dataIndex: 'receiver' },
      ]
    : [
        { title: 'Дугаар', className: 'text-center', dataIndex: 'code' },
        { title: 'Нийт жин', className: 'text-center', dataIndex: 'weight', render: (weight) => `${weight} кг` },
        { title: 'Нийт төлбөр', className: 'text-center', dataIndex: 'amount', render: (amount) => `$${amount}` },
        { title: 'Илгээгч', className: 'text-center', dataIndex: 'sender' },
        { title: 'Хүлээн авагч', className: 'text-center', dataIndex: 'receiver' },
        {
          title: 'Үйлдэл',
          className: 'text-center',
          render: (record) => (
            <>
              <Button className="btn-sb btn-sb-primary me-3" style={{ width: 40 }} onClick={() => navigate(`/admin/orders/${record.id}`)}>
                <FontAwesomeIcon icon={faEye} size="sm" />
              </Button>
              <Button className="btn-sb btn-sb-danger" style={{ width: 40 }} onClick={() => removeOrder(record.packageId, record.code)}>
                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
              </Button>
            </>
          ),
        },
      ]

  const downloadReport = () => {
    client
      .get(`/api/admin/shipment/${shipmentId}/report`, { responseType: 'blob' })
      .then(async (res) => {
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `SBExpress_ShipmentReport_${shipmentId}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        notification.error({
          message: 'Тайлан татаж авахад асуудал гарлаа',
          description: `${err}`,
        })
        console.error(err)
      })
  }

  return (
    <>
      <Helmet title="Богц дэлгэрэнгүй - SB Express" defer={false} />
      <Row gutter={[30, 20]}>
        <Col span={24}>
          <Button type="link" className="sb-arrow-back" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
            <span>Буцах</span>
          </Button>
        </Col>
        <Col span={24}>
          <h4>'{moment(shipmentDetails.date).format('MM/DD/YYYY')}' Богцын дэлгэрэнгүй мэдээлэл</h4>
        </Col>
        <Spin size="large" spinning={loading}>
          <Col span={24}>
            <Form name="shipment-details" form={shipmentDetailsFormRef} onFinish={updateShipmentDetails}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name="totalAmount" label="Нийт төлбөр">
                    <Input placeholder="Нийт төлбөр" className="sb-input" prefix="$" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="totalAmountInUSA" label="АНУ-д">
                    <Input placeholder="АНУ-д" className="sb-input" prefix="$" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="totalAmountInMGL" label="Монголд">
                    <Input placeholder="Монголд" className="sb-input" prefix="$" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="date" label="Огноо">
                    <Input placeholder="Огноо" className="sb-input" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="weight" label="Ачааны нийт жин">
                    <Input className="sb-input" disabled placeholder="Ачааны нийт жин" prefix="кг" size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="shipmentStatus" label="Статус">
                    <Select
                      placeholder="Статус"
                      className="sb-select sb-shadow"
                      popupClassName="sb-select-dropdown"
                      options={shipmentStatuses}
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} size="sm" className="me-2 base-color" />}
                      disabled={isManager() ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="branchId" label="Салбар" rules={[{ required: true, message: 'Салбар сонгоно уу!' }]}>
                    <Select
                      placeholder="Салбар"
                      className="sb-select sb-shadow"
                      popupClassName="sb-select-dropdown"
                      options={branches}
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} size="sm" className="me-2 base-color" />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="shipmentType" label="Төрөл">
                    <Input className="sb-input" disabled placeholder="Төрөл" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="description" label="Тайлбар">
                    <Input.TextArea placeholder="Тайлбар" className="sb-input sb-shadow" rows={4} disabled={isManager() ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Spin>
        <Col flex="220px">
          <Button className="btn-sb btn-sb-primary sb-shadow w-100" onClick={() => downloadReport()}>
            <FontAwesomeIcon icon={faFileDownload} size="sm" className="mx-2" />
            Тайлан авах
          </Button>
        </Col>
        <Col flex="auto" />
        <Col flex="230px">
          {!isManager() && (
            <Button form="shipment-details" htmlType="submit" className="btn-sb btn-sb-primary sb-shadow w-100">
              <FontAwesomeIcon icon={faSave} size="sm" className="mx-2" />
              Хадгалах
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[15, 0]} className="mt-4">
            {subMenus.map((subMenu) => (
              <Col key={subMenu.dataIndex}>
                <Button
                  type="link"
                  className="px-0"
                  onClick={() => setActiveSubMenu(subMenu.dataIndex)}
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: activeSubMenu === subMenu.dataIndex ? '#11427F' : '#333333',
                    borderBottom: activeSubMenu === subMenu.dataIndex ? '1px solid #11427F' : '',
                  }}
                >
                  {subMenu.dataIndex === 'packages'
                    ? `${subMenu.name} (${shipmentDetails?.packages})`
                    : `${subMenu.name} (${shipmentDetails?.orders})`}
                </Button>
              </Col>
            ))}
            <Col flex="auto" />
            <Col flex="255px">
              <Input.Search
                className="sb-search sb-shadow"
                placeholder="Хайх"
                value={activeSubMenu === 'packages' ? searches.packageSearch : searches.orderSearch}
                onChange={(e) => {
                  if (activeSubMenu === 'packages') setSearches({ ...searches, packageSearch: e.target.value })
                  else if (activeSubMenu === 'orders') setSearches({ ...searches, orderSearch: e.target.value })
                }}
                onSearch={(value) => {
                  if (activeSubMenu === 'packages')
                    setPaginatedRequestDataPackage({
                      ...paginatedRequestDataPackage,
                      search: searches.packageSearch,
                      pagination: {
                        ...paginatedRequestDataPackage.pagination,
                        current: 1,
                      },
                    })
                  else if (activeSubMenu === 'orders')
                    setPaginatedRequestDataOrder({
                      ...paginatedRequestDataOrder,
                      search: searches.orderSearch,
                      pagination: {
                        ...paginatedRequestDataOrder.pagination,
                        current: 1,
                      },
                    })
                }}
              />
            </Col>
            {activeSubMenu === 'packages' && !isManager() && (
              <Col flex="230px">
                <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" onClick={() => setAddPackageModalVisible(true)}>
                  <FontAwesomeIcon icon={faPlus} size="sm" className="mx-2" />
                  Багц бүртгэх
                </Button>
              </Col>
            )}
          </Row>
          <Table
            className="sb-table w-100 mt-3"
            dataSource={activeSubMenu === 'packages' ? packagesInfo.tableData : ordersInfo.tableData}
            columns={activeSubMenu === 'packages' ? packagesColumns : ordersColumns}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            loading={tableLoading}
            pagination={
              activeSubMenu === 'packages'
                ? {
                    total: shipmentDetails.packages,
                    current: paginatedRequestDataPackage.pagination.current,
                    pageSize: paginatedRequestDataPackage.pagination.pageSize,
                    onChange: (page, pageSize) => {
                      setTableLoading(true)
                      setPaginatedRequestDataPackage({
                        ...paginatedRequestDataPackage,
                        pagination: { current: page, pageSize },
                      })
                    },
                  }
                : {
                    total: shipmentDetails.orders,
                    current: paginatedRequestDataOrder.pagination.current,
                    pageSize: paginatedRequestDataOrder.pagination.pageSize,
                    onChange: (page, pageSize) => {
                      setTableLoading(true)
                      setPaginatedRequestDataOrder({
                        ...paginatedRequestDataOrder,
                        pagination: { current: page, pageSize },
                      })
                    },
                  }
            }
          />
        </Col>
      </Row>
      {addPackageModalVisible && (
        <CreatePackage
          modalVisible={addPackageModalVisible}
          closeModal={() => setAddPackageModalVisible(false)}
          apiLoading={apiLoading}
          formRef={createPackageFormRef}
          createPackage={createPackage}
        />
      )}
    </>
  )
}

export default ShipmentDetails
